.servicesMainHeading {
  text-align: center;
  color: rgb(182, 18, 18);
  font-weight: bold;
  font-size: 15px;
}

.featuresMainHeading {
  text-align: center;
  font-size: 40px;
}

.featuresImage {
  display: flex;
  margin: auto;
  height: 250px;
  width: 325px;
  border-radius: 20px;
}

.featuresHeading {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 18px;
}

.featuresDescription {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}
