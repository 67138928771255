.contactFormTexts {
  margin-top: 80px;
}

.getInTouch {
  color: rgb(182, 18, 18);
  font-weight: bold;
  font-size: 15px;
}

.contactUs {
  display: block;
  padding-top: 15px;
  font-size: 40px;
}

.location {
  margin-left: 5px;
}

.contactLabel {
  font-weight: bold;
}

.submitButtonDiv {
  text-align: center;
  height: 45px;
  border-radius: 10px;
  background-color: rgb(225, 230, 233);
}

.submitButton {
  border: none;
  border-radius: 10px;
  background-color: rgb(225, 230, 233);
  width: 100%;
  height: 100%;
  display: block;
  font-weight: bold;
}
