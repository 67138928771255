/* body {
  font-family: "Arial", sans-serif;
}

.App {
  text-align: center;
} */

.homeContainer {
  background-color: #141313;
}

.headingContainer {
  margin-top: 100px;
  margin-bottom: 80px;
}

.mainHeading {
  font-size: 60px;
}

.subHeading {
  font-size: 20px;
}

.homePageImage {
  margin-top: 40px;
  border-radius: 3%;
  margin-bottom: 40px;
}
