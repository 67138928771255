.servicesMainHeading {
  text-align: center;
  color: rgb(182, 18, 18);
  font-weight: bold;
  font-size: 15px;
}

.servicesSubHeading {
  text-align: center;
  font-size: 40px;
}

.servicesHeading {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 18px;
}

.serviceDescription {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.serviceTagsRow {
  text-align: center;
}

.serviceTags {
  display: inline-block;
  background-color: #ebd2d2;
  color: rgb(99, 74, 74);
  font-size: 15px;
  font-weight: bold;
  border-radius: 20px;
  padding: 10px;
  margin: 5px;
}
