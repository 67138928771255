.aboutUsMainHeading {
  text-align: center;
  font-size: 40px;
}

.aboutUsDescriptionBlock {
  margin-top: 40px;
}

.aboutUsDescription {
  font-weight: 100;
  font-size: 15px;
  display: block;
  text-align: center;
}
